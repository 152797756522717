import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withNavigation } from 'Common/utils/WithNavigation';

const PaymentPermission = ({children , navigateTo}) => {
    const [hasPaymentPermission , setHasPaymentPermission] = useState(true);

    useEffect(()=>{
        checkToken();
    },[])

    const checkToken = () => {
        let params = {
            paymentToken : localStorage.getItem('paymentToken')
        }

        axios.post(process.env.REACT_APP_API_URL + 'payment/paymenttokenverification', params, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + localStorage.getItem('paymentToken'),
            }
        }).then((response) => {
            if(response.data.status == 'error'){
                setHasPaymentPermission(false);
            }
        }, (error) => {
            console.log(error);
        });

        if (!hasPaymentPermission) {
            console.log('Redirecting to login as token is expired.')
            return navigateTo(`/payment-login/${localStorage.getItem('db')}`);
        }else{
            console.log('ooo')
        }
    }

    return children;
}

export default withNavigation(PaymentPermission);
