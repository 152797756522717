export const Login = (user) => {
    return { type: 'LOGIN', payload:user }
}
export const Logout = () => {
    return { type: 'LOGOUT', logOut:true }
}
export const PageRefresh = (val) => {
    return { type: 'PAGEREFRESH', pageRefresh: val }
}
export const AlertText = (val) => {
    return { type: 'ALERTTEXT', alertText: val }
}