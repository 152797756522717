import React, { Component } from 'react';
import Routes from 'Routes';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'styles/style.css';
import 'styles/reset.css';
import 'styles/common.css';
import {disableReactDevTools} from 'disableDevTools';
class App extends Component {
  constructor() {
    super();
    this.state = {}
  }
  render() {
    if (process.env.REACT_APP_ENV === "production") disableReactDevTools();
    return (
      <div>        
          <Routes />                
      </div>
    );
  } 
}

export default App;
