import { useAuth } from "Contexts/User";
import Authenticate from 'components/Account/components/Authenticate'

/**
 * owner portal routes permission
 */
export function OwnerRoutePer(){ 
  const [state, dispatch ] = useAuth();
  const auth = new Authenticate();
  const userInfo = state?.user;

  return (
    [{
      page:"home",
      condition: true// (userInfo?.hideHomeInfo == 0)
    }, 
    {
      page:"account",
      condition:(userInfo?.HideCharges == 0)
    }, 
    {
      page:"contacts",
      condition:(userInfo?.HideContactInfo == 0)
    }, 
    {
      page:"documents",
      condition:(userInfo?.HideDocuments == 0)
    }, 
    {
      page:userInfo?.tab5_url,
      condition:(userInfo?.tab5 != '')
    }, 
    {
      page:userInfo?.tab6_url,
      condition:(userInfo?.tab6 != '')
    }, 
    {
      page:userInfo?.tab7_url,
      condition:(userInfo?.tab7 != '')
    },
    {
      page: "statement",
      condition: (userInfo?.HideStatement ==0)
    },    
    {
      page: "deliquent-report",
      condition: (userInfo?.HideDelinquentRentRpt ==0)
    },
    {
      page: "choosecompany",
      condition:true
    }
  ]
  );
}
export function MaintechRoutePer(){
  const [state, dispatch ] = useAuth();
  const auth = new Authenticate();
  const userInfo = state?.user;

  return (
    [{
      page:"home",
      condition: (userInfo?.tab1 != '')
    }, 
    {
      page:userInfo?.tab2_url,
      condition: (userInfo?.tab2 != '')
    }, 
    {
      page:userInfo?.tab3_url,
      condition: (userInfo?.tab3 != '')
    }, 
    {
      page:userInfo?.tab4_url,
      condition: (userInfo?.tab4 != '')
    },
    {
      page:"inspection",
      condition: true //(userInfo?.tab5 != '')
    },
    
  ]
  );
}