import {useState} from 'react';
import { MDBAlert } from 'mdb-react-ui-kit';

const Alert = ({color, classes, onClose, children }) => {
    const [visibility, setVisibility] = useState(true);
    return (
        <div className='alert-container'>
            <MDBAlert open={visibility} className='w-100' color={color} onClose={onClose}>
                {children}
                {onClose != undefined && <button type='button' aria-label='Close' className="btn-close float-end" onClick={() => setVisibility(false)} />}
            </MDBAlert>
        </div>
      
    );
};
export default Alert;
