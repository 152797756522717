import React from 'react';
import { useNavigate , useLocation, useParams  } from 'react-router-dom';

export const withNavigation = (WrappedComponent) => {
    return function WithNavigation(props) {
        const navigate = useNavigate();
        const location = useLocation();
        const params = useParams();
        
        const navigateTo = (pathname, state) => {
            if (state) {
                navigate(pathname, { state });
            } else {
                navigate(pathname);
            }
        }

        const getLocation = () => {
            return location;
        };
        const getParams  = () => {
            return params;
        }

        return <WrappedComponent {...props} navigateTo={navigateTo} getLocation={getLocation} getParams={getParams} />;
    };
};

export const withParams = (WrappedComponent) => {
    return function WithNavigation(props) {
        const params = useParams();

        const getParams  = () => {
            return params;
        }

        return <WrappedComponent {...props} getParams={getParams} />;
    };
};