import React from 'react';
import { MDBTooltip, MDBContainer } from 'mdb-react-ui-kit';

const Tooltip = ({tag , placement , children , title}) => {
    return (                           
        <MDBTooltip  tag={tag} wrapperProps={{ color: 'secondary' }} placement={placement} title={title} >
            {children}
        </MDBTooltip>
    );
};
export default Tooltip;
