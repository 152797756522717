import React, 
{ lazy,
  Suspense 
} from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation
} from 'react-router-dom';
import Loader from "components/Common/components/Loader/Loader";
import {OwnerRoutePer, MaintechRoutePer} from "Common/Permission/Permission"
import { UserContextProvider } from "Contexts/User";
import AuthRoute from "Routes/AuthRoute";
import PaymentPermission from "./Common/Permission/PaymentPermission";
import Payment from "./components/Payment/Payment";
import PaymentConfirm from "./components/Payment/PaymentConfirm";
import PaymentReceipt from "./components/Payment/PaymentReceipt";
import TermsAndConditions from "./components/Payment/TermsAndConditions";
import RedirectToURL from "Common/utils/RedirectToURL";
/**
 * Lazy will load components module wise
 */
const Login = lazy(() => import('components/Account/components/Login/Login'));
const Logout = lazy(() => import('components/Account/components/Logout/Logout'));
const OwnerLogin = lazy(() => import('components/Account/components/Login/OwnerLogin'));
const LogoutOwner = lazy(() => import('components/Account/components/Logout/LogoutOwner'));
const PaymentLogin = lazy(() => import('components/Account/components/Login/PaymentLogin'));
const Signup = lazy(() => import('components/Account/components/Signup/Signup'));
const OwnerSignup = lazy(() => import('components/Account/components/Signup/OwnerSignup'));
const Reset = lazy(() => import('components/Account/components/Reset/Reset'));
const OwnerReset = lazy(() => import('components/Account/components/Reset/OwnerReset'));
const Thankyou = lazy(() => import('components/Account/components/Reset/Thankyou'));
const OwnerThankyou = lazy(() => import('components/Account/components/Reset/OwnerThankyou'));
const MaintechThankyou = lazy(() => import('components/Account/components/Reset/MaintechThankyou'));
const NotFound = lazy(() => import('components/NotFound/NotFound'));
const IdentifyAccount = lazy(() => import('components/Payment/IdentifyAccount'));
const IdentifyAccountProspect = lazy(() => import('components/Payment/IdentifyAccountV1'));
const NotAuthorized = lazy(() => import('components/NotFound/NotAuthorized'));
const NotAuthorizedMaintech = lazy(() => import('components/NotFound/NotAuthorizedMaintech'));
/**
 * Routes for Resident Module
 */
const Home = lazy(() => import('components/Resident/Home'));
const Charges = lazy(() => import('components/Resident/Charges'));
const Documents = lazy(() => import('components/Resident/Documents'));
const WorkOrders = lazy(() => import('components/Resident/Workorders'));
const NewWorkOrder = lazy(() => import('components/Resident/NewWorkOrder'));
const Contacts = lazy(() => import('components/Resident/Contacts'));
const ContactDetail = lazy(() => import('components/Resident/ContactDetail'));
const Rcr = lazy(() => import('components/Resident/Rcr'));
const RcrDetail = lazy(() => import('components/Resident/Rcrdetail'));
const RcrPrompt = lazy(() => import('components/Resident/RcrPrompt'));
const Statement = lazy(() => import('components/Resident/Statement'));
const Chooselease = lazy(() => import('components/Resident/Chooselease'));
const LeaseListings = lazy(() => import('components/Resident/LeaseListings'));
const RecurringPayment = lazy(() => import('components/Resident/RecurringPayment'));
const RecurringThanks = lazy(() => import('components/Resident/RecurringThanks'));
const ChangePass = lazy(() => import('components/Resident/Changepass'));
const Smstermconditions = lazy(() => import('components/Resident/Smstermconditions'));
const RenewalIntention = lazy(() => import('components/RenewalIntention/Main'));
/**
 * Routes for Prospect Module
 */
 const ProspectInfo = lazy(() => import('components/Prospect/ProspectInfo'));
 const ProspectContacts = lazy(() => import('components/Prospect/Contacts'));
 const ProspectDocuments = lazy(() => import('components/Prospect/Documents'));
 const ProspectStatement = lazy(() => import('components/Prospect/Statement'));
 const ProspectSmstermconditions = lazy(() => import('components/Prospect/Smstermconditions'));

/**
 * Routes for Owner Module
 */
const OwnerHome = lazy(() => import('components/Owner/Home'));
const OwnerDocuments = lazy(() => import('components/Owner/Documents'));
const OwnerContacts = lazy(() => import('components/Owner/Contacts'));
const OwnerStatement = lazy(() => import('components/Owner/Statement'));
const DeliquentReport = lazy(() => import('components/Owner/DeliquentReport'));
const ChooseCompany = lazy(() => import('components/Owner/ChooseCompany'));
const OwnerAccount = lazy(()=>import('components/Owner/Account'))
const OwnerWorkOrder = lazy(()=>import('components/Owner/Workorders'))
const OwnerSmstermconditions = lazy(() => import('components/Owner/Smstermconditions'));
const OwnerChangePass = lazy(() => import('components/Owner/Changepass'));

/**
 * Routes for Maintech Portal
 */
const MaintechHome = lazy(() => import('components/MainTech/Home'));
const MaintechLogin = lazy(() => import('components/Account/components/Login/MainTechLogin'));
const MaintechSignup = lazy(() => import('components/Account/components/Signup/MainTechSignup'));
const MaintechReset = lazy(() => import('components/Account/components/Reset/MainTechReset'));
const MaintechChangePass = lazy(() => import('components/MainTech/Changepass'));
const LogoutMaintech = lazy(() => import('components/Account/components/Logout/LogoutMaintTech'));
const MaintechWorkOrder = lazy(()=>import('components/MainTech/Workorders'));
const MaintechInspection = lazy(()=>import('components/MainTech/Inspection/Inspection'))
const MaintechInspectionPrompt = lazy(()=>import('components/MainTech/Inspection/InspectionPrompt'))
const MaintechInspectionDetail = lazy(()=>import('components/MainTech/Inspection/InspectionDetail'))


/**
/**
 * For optional param add ? sign behind param
 */


/**
 * Aptexx Payment method urls
 */
const PaymentForm = lazy(() => import('components/PaymentV1/PaymentForm'));
const PaymentLoginV1 = lazy(() => import('components/Account/components/Login/PaymentLoginV1'));
const PaymentV1 = lazy(() => import('components/PaymentV1/Payment'));
const PaymentConfirmV1 = lazy(() => import('components/PaymentV1/PaymentConfirm'));
const PaymentReceiptV1 = lazy(() => import('components/PaymentV1/PaymentReceipt'));
const TermsAndConditionsV1 = lazy(() => import('components/PaymentV1/TermsAndConditions'));
const IdentifyAccountV1 = lazy(() => import('components/PaymentV1/IdentifyAccount'));

const Index = () => (
    <BrowserRouter basename={process.env.REACT_APP_BASE}>
        <Suspense fallback={<Loader show="true" />}>
            <UserContextProvider>
              <Routes>
                {/* Payment  */}
                <Route exact path="/payment-login/:customer?" element={<PaymentLogin/>} />
                <Route exact path="/payment" element={<PaymentPermission><Payment/></PaymentPermission>} />
                <Route exact path="/payment-confirm" element={<PaymentPermission><PaymentConfirm/></PaymentPermission>} />
                <Route exact path="/payment-receipt" element={<PaymentPermission><PaymentReceipt/></PaymentPermission>} />
                <Route exact path="/terms-and-conditions" element={<PaymentPermission><TermsAndConditions/></PaymentPermission>} />
                <Route exact path="/identifyaccount" element={<IdentifyAccount/>} />
                <Route exact path="/identifyprospectaccount" element={<IdentifyAccountProspect />} />


                {/* Aptexx Payment  */}
                {/* <Route exact path="/payment-loginV1/:customer?" element={PaymentLoginV1} />
                <Route exact path="/paymentV1" element={PaymentV1} />
                <Route exact path="/payment-confirmV1" element={PaymentConfirmV1} />
                <Route exact path="/payment-receiptV1" element={PaymentReceiptV1} />
                <Route exact path="/terms-and-conditionsV1" element={TermsAndConditionsV1} />
                <Route exact path="/identifyaccountV1" element={IdentifyAccountV1} /> */}

                {/* Resident Portal */}
                <Route exact path="/resident/home" element={<Home />} />
                <Route exact path="/resident/charges" element={<Charges />} />
                <Route exact path="/resident/documents" element={<Documents/>} />
                <Route exact path="/resident/new-work-order" element={<NewWorkOrder/>} />
                <Route exact path="/resident/contacts" element={<Contacts/>} />
                <Route exact path="/resident/contact-detail/:id" element={<ContactDetail/>} />
                <Route exact path="/resident/rcr" element={<Rcr/>} />
                <Route exact path="/resident/rcrdetail/:id" element={<RcrDetail/>} />
                <Route exact path="/resident/rcrprompt/:id" element={<RcrPrompt/>} />
                <Route exact path="/resident/statement" element={<Statement/>} />
                <Route exact path="/resident/chooselease" element={<Chooselease />} />
                <Route exact path="/resident/leaselistings" element={<LeaseListings/>} />
                <Route exact path="/resident/recurring-payment" element={<RecurringPayment/>} />
                <Route exact path="/resident/recurring-thanks" element={<RecurringThanks/>} />
                <Route exact path="/resident/workorderdetail" element={<WorkOrders/>} />
                <Route exact path="/resident/changepass/:customer?" element={<ChangePass/>} />
                <Route exact path="/resident/smstermconditions" element={<Smstermconditions/>} />
                <Route exact path="/resident/renewal" element={<RenewalIntention/>} />
                <Route exact path="/resident/workorder/:page?"
                 element={
                  <WorkOrders
                  />
                  }
                 />
                {/* Prospect Portal */}                
                <Route exact path="/prospect/prospectinfo" element={<ProspectInfo />} />
                <Route exact path="/prospect/contacts" element={<ProspectContacts/>} />
                <Route exact path="/prospect/documents" element={<ProspectDocuments/>} />
                <Route exact path="/prospect/statements" element={<ProspectStatement/>} />
                <Route exact path="/prospect/smstermconditions" element={<ProspectSmstermconditions/>} />

                {/* Owner Portal */}
                <Route path="/owner/home" element={
                  <AuthRoute PermissionObj={OwnerRoutePer}>
                    <OwnerHome/>
                  </AuthRoute>
                  }
                />
                <Route path="/owner/account" element={
                  <AuthRoute PermissionObj={OwnerRoutePer}>
                    <OwnerAccount/>
                  </AuthRoute>
                  }
                />
                <Route path="/owner/documents" element={
                  <AuthRoute PermissionObj={OwnerRoutePer}>
                    <OwnerDocuments/>
                  </AuthRoute>
                  }
                />
                <Route
                  path="/owner/workorders/:page?"
                  element={
                    <AuthRoute PermissionObj={OwnerRoutePer}>
                      <OwnerWorkOrder page="workorders"/>
                    </AuthRoute>                    
                  }
                />
                <Route path="/owner/contacts" element={
                  <AuthRoute PermissionObj={OwnerRoutePer}>
                    <OwnerContacts/>
                  </AuthRoute>
                  }
                />
                <Route path="/owner/statement" element={
                  <AuthRoute PermissionObj={OwnerRoutePer}>
                    <OwnerStatement/>
                  </AuthRoute>
                  }
                />
                <Route path="/owner/deliquent-report" element={
                  <AuthRoute PermissionObj={OwnerRoutePer}>
                    <DeliquentReport/>
                  </AuthRoute>
                  }
                />
                <Route path="/owner/choosecompany" element={<ChooseCompany/>}
                />
                <Route path="/owner/smstermconditions" element={
                  <AuthRoute PermissionObj={OwnerRoutePer}>
                    <OwnerSmstermconditions/>
                  </AuthRoute>
                  }
                />
                {/* Maintech portal */}
                <Route path="/maintTech/home" element={
                    <AuthRoute PermissionObj={MaintechRoutePer} routeFrom="maintech">
                      <MaintechHome />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/maintTech/workorder/:page?"
                  element={
                    <AuthRoute routeFrom="maintech" PermissionObj={MaintechRoutePer}>
                      <MaintechWorkOrder />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/maintTech/inspection"
                  element={
                    <AuthRoute routeFrom="maintech" PermissionObj={MaintechRoutePer}>
                      <MaintechInspection page="inspection" />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/maintTech/inspectionsprompt/:id"
                  element={
                    <AuthRoute routeFrom="maintech" PermissionObj={MaintechRoutePer}>
                      <MaintechInspectionPrompt page="inspection" />
                    </AuthRoute>                    
                  }
                />
                <Route
                  path="/maintTech/inspectionsdetail/:id"
                  element={
                    <AuthRoute routeFrom="maintech" PermissionObj={MaintechRoutePer}>
                      <MaintechInspectionDetail page="inspection" />
                    </AuthRoute>
                  }
                />
              

                
                {/* Resident Login */}
                <Route exact path="/resident/login/:customer?" element={<Login />} />
                <Route exact path="/login/:customer?" element={<RedirectToURL url='/resident/login/'/>} />

                <Route exact path="/resident/signup/:customer?" element={<Signup/>} />
                <Route exact path="/signup/:customer?" element={<RedirectToURL url='/resident/signup/'/>} />                
                <Route exact path="/resident/reset/:customer?" element={<Reset/>} />
                <Route exact path="/reset/:customer?" element={<RedirectToURL url='/resident/reset/'/>} />  
                <Route exact path="/resident/thank-you/:customer?" element={<Thankyou/>} />
                <Route exact path="/thank-you/:customer?" element={<RedirectToURL url='/resident/thank-you/'/>} /> 

                <Route exact path="/logout/:customer?" element={<Logout/>} />
                <Route exact path="/logoutowner/:customer?" element={<LogoutOwner/>} />
                <Route exact  path="/not-authorized" element={<NotAuthorized/>} />
                <Route exact  path="/not-authorized-maintTech" element={<NotAuthorizedMaintech/>} />

              
                {/* Owner Login */}
                <Route exact path="/owner/login/:customer?" element={<OwnerLogin/>} />
                <Route exact path="/owner-login/:customer?" element={<RedirectToURL url='/owner/login/'/>}/>

                <Route exact path="/owner/signup/:customer?" element={<OwnerSignup/>} />
                <Route exact path="/owner-signup/:customer?" element={<RedirectToURL url='/owner/signup/'/>} />

                <Route exact path="/owner/reset/:customer?" element={<OwnerReset/>} />
                <Route exact path="/owner-reset/:customer?" element={<RedirectToURL url='/owner/reset/'/>} />
                <Route exact path="/owner/thank-you/:customer?" element={<OwnerThankyou/>} />
                <Route exact path="/owner-thank-you/:customer?" element={<RedirectToURL url='/owner/thank-you/'/>} />

                {/* Maintech Auth Routes */}
                <Route exact path="/maintTech/login/:customer?" element={<MaintechLogin/>} />
                <Route exact path="/maintTech-login/:customer?" element={<RedirectToURL url='/maintTech/login/'/>} />
                
                <Route exact path="/maintTech/signup/:customer?" element={<MaintechSignup/>} />
                <Route exact path="/maintTech-signup/:customer?" element={<RedirectToURL url='/maintTech/signup/'/>} />
                <Route exact path="/maintTech/reset/:customer?" element={<MaintechReset/>} />
                <Route exact path="/maintTech-reset/:customer?" element={<RedirectToURL url='/maintTech/reset/'/>} />
                <Route exact path="/maintTech/thank-you/:customer?" element={<MaintechThankyou/>} />
                <Route exact path="/maintTech-thank-you/:customer?" element={<RedirectToURL url='/maintTech/thank-you/'/>} />

                <Route exact path="/maintTech/changepass/:customer?" element={<MaintechChangePass/>} />
                <Route exact path="/owner/changepass/:customer?" element={<OwnerChangePass/>} />

                <Route exact path="/logoutmaintech/:customer?" element={<LogoutMaintech/>} />
                {/* maintTech auth routes */}

                
                <Route exact path="/" element={<Login/>} />
                <Route exact path="*" element={<NotFound/>} />
               </Routes>
            </UserContextProvider>
        </Suspense>
    </BrowserRouter>
);
export default Index;



