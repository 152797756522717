import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import logo from 'styles/logo.png';
import Loader from 'components/Common/components/Loader/Loader';
import Authenticate from 'components/Account/components/Authenticate';
import Tooltip from 'components/Common/components/custom/UiKit/Tooltip';
import NotFound from "../NotFound/NotFound";
import { withNavigation } from 'Common/utils/WithNavigation';
/**
 * Payment confirm page
 */
class PaymentConfirm extends Component {
    constructor(props) {
        /** Props will make all component properties available in the constructor */
        super(props);
        this.auth = new Authenticate();
        this.state = {
            response: {},
            errorMessage: '',
            errorMessageClass: '',
            loader: false,
            redirect: false
        }
        this.params = localStorage.getItem('params');
        this.params = JSON.parse(this.params);
        var today = new Date();
        let date =  ('0' +(today.getMonth() + 1)).slice(-2) + '/' + ('0' +today.getDate()).slice(-2) + '/' + today.getFullYear();
        this.params.currentDate = date;
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    submitPayment() {
        this.setState({ loader: true });
        axios.post(process.env.REACT_APP_API_URL + 'payment/process', this.params, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + localStorage.getItem('paymentToken'),
            }
        })
            .then((response) => {
                this.setState({ loader: false });
                if (response.status === 200) {
                    if (typeof (response.data.status) === 'undefined') {
                        alert('Something wrong with API call. Please contact site Admin.');
                    } else {
                        if (response.data.status === 'error') {
                            if (response.data.status_code === '401') {
                                this.setState({
                                    status_code: response.data.status_code,
                                });
                            }
                            else {
                                this.setState({
                                    errorMessage: response.data.message,
                                    errorMessageClass: 'alert alert-danger'
                                });
                            }
                        } else {
                            //Redirect to account page                   
                            this.params.invoice_number = response.data.data.invoice_number;
                            this.params.net_amount = response.data.data.net_amount;
                            this.params.applicationURL = response.data.data.applicationURL;
                            localStorage.setItem('params', JSON.stringify(this.params));
                            this.setState({
                                errorMessage: response.data.message + " Please wait generating your receipt.",
                                errorMessageClass: 'alert alert-success',
                                redirect: true
                            });
                        }
                    }
                } else {
                    this.setState({ errorMessage: 'Error occcured with status code ' + response.status });
                }
            }, (error) => {
                this.setState({ loader: false });
                console.log(error);
            });
    }
    redirect() {
        if (this.state.redirect === true) {
            return this.props.navigateTo("/payment-receipt");
        }
    }

    /** For Logout */
    logout = () => {
        var sPropertyTransitId = this.auth.userInfo.sPropertyTransitId;
        localStorage.clear();
        localStorage.setItem('db',sPropertyTransitId);
        localStorage.setItem('customer',sPropertyTransitId);
        window.location.href = '/payment-login/' + sPropertyTransitId;
    }
    /** End Logout Function */

    /**    
     * Function will identify enter key press.  if enter key is pressed click event for submit button will trigger.
     * @param {*} e for event
     */
    render() {
        if (this.params === null) {
            return <NotFound />;
        }
        if (this.state.status_code) {
            return this.props.navigateTo("/logout");
        }
        let creditcard = ''
        if (this.params.payment_method === 'creditCard') {
            creditcard = <div className="credit-card">
                <div className="review-payment-container">
                    <label>Card Number:</label>
                    <div className="review-payment-details">Ending in {this.params.card_number.substr(12, 4)}</div>
                </div>
                <div className="review-payment-container">
                    <label>Expiry Date:</label>
                    <div className="review-payment-details">{this.params.expiration_date.replace('-', '/')}</div>
                </div>
                <div className="review-payment-container">
                    <label>Full Name:</label>

                    <div className="review-payment-details full-name-length">
                        <Tooltip
                            tag="span"
                            placement="top"
                            title={this.params.name_on_card}
                        >
                            <div> {this.params.name_on_card} </div>
                        </Tooltip>
                    </div>
                </div>
            </div>;
        };

        let bankdraft = ''
        if (this.params.payment_method === 'bankDraft') {
            bankdraft = <div className="bank-draft">
                <div className="review-payment-container">
                    <label>Account Type:</label>
                    <div className="review-payment-details text-capitalize">{this.params.account_type}</div>
                </div>
                <div className="review-payment-container">
                    <label>Routing Number:</label>
                    <div className="review-payment-details">{this.params.routing_no}</div>
                </div>
                <div className="review-payment-container">
                    <label>Account Number:</label>
                    <div className="review-payment-details">{this.params.account_no}</div>
                </div>
                <div className="review-payment-container">
                    <label>Name of Bank:</label>
                    <div className="review-payment-details full-name-length">
                        <Tooltip
                            tag="span"
                            placement="top"
                            title={this.params.bank_name}
                        >
                                <div>{this.params.bank_name} </div>
                        </Tooltip>
                        

                    </div>
                </div>
                <div className="review-payment-container">
                    <label>Name of Bank Account:</label>
                    <div className="review-payment-details full-name-length">
                        <Tooltip
                            tag="span"
                            placement="top"
                            title={this.params.name_bank_account}
                        >
                            <div>{this.params.name_bank_account} </div>
                        </Tooltip>
                    </div>
                </div>
            </div>;
        }

        return (
            <div className="payment-portal-wrapper">
                <Authenticate token='' />
                {this.redirect()}
                <div className="payment-portal-header">
                    <div className="payment-portal-container-main">
                        <div className="payment-portal-logo"><img src={logo} alt="Logo" /></div>
                        <div className="header-account-links">
                            <ul>
                                <li><span className="logout-text">{this.auth.userInfo.customer_name}, </span>
                                    <Link to="#" onClick={this.logout}><span className="logout-text">Logout</span> <i className="fa fa-power-off fa-lg" title="Log Out"></i></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <h1>Review Payment Details</h1>
                <div className="payment-portal-container">
                    <div className="payment-portal-container-header">
                        In the amount of ${Number(this.params.amount_to_pay) + Number(this.params.service_fees)} (${this.params.amount_to_pay} + ${this.params.service_fees} Convenience Fee)
                    </div>
                    <div className={this.state.errorMessageClass}>
                        {Object.keys(this.state.errorMessage).map(key =>
                            <span key={key}>{this.state.errorMessage[key]}</span>
                        )}
                    </div>
                    <h2>Payment Details</h2>
                    {/* <Link to="/payment/" className="simple-link edit-payment" title="Edit Payment">Edit Payment</Link> */}
                    <div className="review-payment">
                        <label>Payment Type:</label>
                        <div className="review-payment-details">{this.params.payment_method === 'creditCard' ? 'Credit Card' : 'Bank Draft'}</div>
                    </div>
                    {creditcard}
                    {bankdraft}

                    <div className="review-payment-container">
                        <label>Address:</label>
                        <div className="review-payment-details">
                            <div>{this.params.address_line1}<br />{this.params.address_line2}</div>
                            <div>{this.params.city}, {this.params.zip}, {this.params.state}, {this.params.country}</div>
                        </div>
                    </div>
                    <div className="review-payment-disclaimer">
                        <p className="review-payment-disclaimer-important">By clicking the "Pay Now" button below, I authorize, agree, and/or stipulate to the following:</p>
                        <p>{this.auth.userInfo.sCompanyName} may initiate an electronic funds transfer for the payment described above from the bank account I designated above under the terms of this authorization.<br /><br />
                            {this.auth.userInfo.sCompanyName} may initiate and my financial institution will process this electronic fund transfer using either an Automated Clearing House (“ACH”) debit entry, or alternatively by paper draft in the event such item cannot be electronically debited.<br /><br />
                        Age and the transaction underlying the origination of the electronic debit to my account complies with U.S. State and Federal law. My payment will be automatically debited from my account on the designated payment date or, if such date falls on a weekend or bank holiday then on the next business day.<br /><br />
                            {this.auth.userInfo.sCompanyName} may re-debit my account for the payment in the event such is dishonored by my financial institution and is additionally authorized to charge a return item processing fee according to the laws governing my state.<br /><br />
                        I have read and agree to the Terms of Use. (see icon at bottom of page)<br /><br />
                        My Account will be charged the payment described above immediately and there are sufficient funds in my account to cover this charge.</p>
                    </div>
                    <Link to="/payment" className="simple-link button payment-edit-btn edit">Edit Payment</Link>
                    <button className="button payment-edit-btn" type="button" onClick={() => this.submitPayment()}>Pay Now</button>
                   
                    <div className="payment-portal-disclaimer">
                        <p>This information was obtained from sources believed to be reliable, but no representations or warranties, expressed or implied, as to the accuracy of the information are made.  You are responsible for verifying the information and bear  all risk for inaccuracies.  Pricing and other details are subject to change.  Contact the leasing office for the latest information.</p>
                        <Link to="/terms-and-conditions" target='_blank' className="simple-link">View Terms &amp; Conditions</Link>
                        <img src={logo} alt="Logo" />
                    </div>
                </div>
                <Loader show={this.state.loader} />
            </div>
        );
    }
}

export default withNavigation(PaymentConfirm);
