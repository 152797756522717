import 
  React,
{ 
  useEffect, 
  useRef, 
  useState 
} from "react";
import {
   useLocation
} from 'react-router-dom';
import Authenticate from 'components/Account/components/Authenticate'
import { useAuth } from "Contexts/User";
import { setUser } from "Common/utils/user";
import { setUserMaintech } from "Common/utils/maintechUser";
import { PageRefresh } from 'Contexts/User/action';
import { withNavigation } from "Common/utils/WithNavigation";

const AuthRoute = ({navigateTo , children , routeFrom = "owner",  ...rest}) => {
    const auth = new Authenticate();
    const {PermissionObj} = rest;
    const permissionItems = PermissionObj();
    const path = useLocation();
    const pathSearch= path.search.slice(3);
    let page = path.pathname.split("/")[2]; 
    page = page == 'inspectionsprompt' ? 'inspection' : page;
    page = page == 'inspectionsdetail' ? 'inspection' : page;
    if (page == 'workorder' || page == 'workorders') {
      page = pathSearch;
    }
    const routeobj = permissionItems.find(r=>r.page==page);
    const [state, dispatch] = useAuth();
    const [redirctTo, setRedirctTo] = useState(false);
    const [redirctToMaintech, setRedirctToMaintech] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(true);
    useEffect(() => {
      if (state.user !=null && routeobj.condition == false){
        setIsAuthorized(false)
      }
      isLoggedIN();
    }, [(routeobj && routeobj.condition , state.user)])
  
    /**
     * check user login session time & refresh browser
     */
    const isLoggedIN=() => {
      /**
       * user detail on refresh browser
       */
      if ((state.user === null && auth.token !=undefined) ) {
        if(routeFrom=='maintech'){
          setUserMaintech(dispatch);
        }
        else{
          setUser(dispatch);
        }
      }
      /**
       * api session time out
       */
      if (state.logOut && routeFrom =='owner') {
          setRedirctTo(true)
      }
      if (state.logOut && routeFrom =='maintech') {
        setRedirctToMaintech(true)
      }
      if (localStorage.getItem('ownerDetail') == null && routeFrom =='owner') {
        setRedirctTo(true)
      }
      if (localStorage.getItem('maintechDetail') == null && routeFrom =='maintech') {
        setRedirctToMaintech(true)
      }
    }
  
    if(redirctTo){
      console.log("user to be logged out as session goes expire from owner")
      return navigateTo("/logoutowner");
    }
    if(redirctToMaintech){
      console.log("user to be logged out as session goes expire from maintech")
      return navigateTo("/logoutmaintech");
    }
    if(!isAuthorized && routeFrom =='owner'){
      console.log("user not authorized")
      return navigateTo("/not-authorized");
    }
    if(!isAuthorized && routeFrom =='maintech'){
      console.log("user not authorized")
      return navigateTo("/not-authorized-maintTech");
    }
    if(routeFrom == 'maintech' && state.pageRefresh == true) {
      dispatch(PageRefresh(false));
      return React.cloneElement(children, { key: Date.now() });
    } else {;
      return children;
    }
  }

export default withNavigation(AuthRoute);