import React, { Component } from 'react';
import Page404 from 'styles/images/404.png';
class NotFound extends Component {
    render() {
        return (
            <div className="page-not-found">
                <img src={Page404} alt="Page not found" />
            </div>
        );
    }
}

export default NotFound;