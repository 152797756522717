import React from 'react'
import { useLocation , Navigate} from 'react-router-dom';
import { useParams } from 'react-router-dom';


const RedirectToURL = ({url}) => {
    const location = useLocation();
    const { customer } = useParams();

    // Construct the URL to redirect to
    let redirectTo = `${url}${customer ? `${customer}` : ''}`;

    // Append query parameters from the current location
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.toString()) {
        redirectTo += `?${searchParams.toString()}`;
    }

    // Use Navigate to perform the redirection
    return <Navigate to={redirectTo} replace />;
}

export default RedirectToURL;
