import React, 
{ 
  useReducer, 
  createContext,
  useContext
} from "react";

export const UserContext = createContext();
const initialState = {
    isAuthenticated: false,
    user: null,
    token: null,
    logOut:false,
    pageRefresh: false,
    alertText:''

};
const reducer = (state, action) => {
    switch (action.type) {
      case "LOGIN":
        return {
          ...state,
          isAuthenticated: true,
          user: action.payload,
          logOut:false
        };
      case "LOGOUT":
        return {
          ...state,
          isAuthenticated: false,
          user: null,
          logOut:action.logOut
        };
      case "PAGEREFRESH":
        return {
          ...state,
          pageRefresh: action.pageRefresh
        };
      case "ALERTTEXT":
        return {
          ...state,
          alertText: action.alertText
        };        
      default:
        return state;
    }
};
export const UserContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <UserContext.Provider value={[state, dispatch]}>
        {props.children}
    </UserContext.Provider>
  );
};
export const  useAuth = () => {
  return useContext(UserContext);
}