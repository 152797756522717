import React, { Component } from 'react';

class Loader extends Component {
    constructor() {
        super();
        this.state = {
            show: true
        };
    }
    render() {
        return (            
            <div className={this.props.show === false ? 'loader-hide' : 'loader-show'} >
                <div className="overlay-sub"></div>
                <div className="form-processing" >
                    <div className="loader-image"></div>
                </div>
            </div>
        )
    }
}
export default Loader;