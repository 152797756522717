import { Login, Logout } from "Contexts/User/action";
import axios from 'axios';
import Authenticate from 'components/Account/components/Authenticate'

export function setUser(dispatch) {
    var auth = new Authenticate();
    let customer = localStorage.getItem('customer');
    let userToken = localStorage.getItem('userToken');
    let params = {
        customer: customer,
        userToken: userToken,
    };
    axios.post(process.env.REACT_APP_API_URL + 'owners/getuserdetail', params,{
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + auth.token,
        }
    })
    .then((response) => {
        if (response.status === 200) {
            if (response.data.status === 'error') {
                if (response.data.status_code === 401) {
                    dispatch(Logout());
                }
            }
        }
        if (response.data.status_code !== 401) {
            if (response.data.status == 'error') {
                dispatch(Logout());
            }else{
                dispatch(Login(response.data.data));
            }
        }
    }, (error) => {
        console.log('Error:', error);
        dispatch(Logout());
    });  
}

export async function logoutUser(logoutToken) {
    let params = {       
        token: logoutToken
    };
    return axios.post(process.env.REACT_APP_API_URL + 'logout', params, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    }).then((response) => 
        response
    ).catch((error) => {
        console.log(error);
    });
}