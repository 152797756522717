import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import logo from 'styles/logo.png';
import SimpleReactValidator from 'simple-react-validator';
import Loader from 'components/Common/components/Loader/Loader'
import Authenticate from 'components/Account/components/Authenticate'
import DatePicker from "react-datepicker";
import format from "date-fns/format";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import '../../styles/payment.css';
import moment from 'moment';
import Alert from 'components/Common/components/custom/UiKit/Alert';
import Select from 'components/Common/components/custom/UiKit/Select';
import { withNavigation } from 'Common/utils/WithNavigation';


/**
 * payment page
 */
class Payment extends Component {
    constructor(props) {
        /** Props will make all component properties available in the constructor */
        super(props);
        this.countries = [];
        this.auth = new Authenticate();
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        let params = localStorage.getItem('params');
        let country = 'US';
        let formType = 'creditCard';
        let routing_no = '';
        let routing_no_re = '';
        let account_type = '';
        let card_no = '';
        let exp_date = '';
        let card_date_object = '';
        let name_on_card = '';
        let address1 = '';
        let address2 = '';
        let city = '';
        let state = '';
        let zip_code = '';
        let phone = '';
        let account_no = '';
        let account_no_re = '';
        let bank_name = '';
        let name_bank_account = '';
        let amount = '';
        var pendingPaymentMessage = [];
        if (params !== '' && params !== null && params !== "null") {
            params = JSON.parse(params);
            country = params.country;
            routing_no = params.routing_no;
            routing_no_re = params.routing_no;
            formType = params.payment_method;
            account_type = params.account_type;
            card_no = params.card_number;
            if (params.card_date_object !== '') {
                card_date_object = new Date(params.card_date_object);
                exp_date = format(new Date(params.card_date_object), 'MM/yyyy');
            }
            name_on_card = params.name_on_card;
            // =ard_security_code: params.cvv;
            country = params.country;
            address1 = params.address_line1;
            address2 = params.address_line2;
            city = params.city;
            state = params.state;
            zip_code = params.zip;
            phone = params.phone;
            account_no = params.account_no;
            account_no_re = params.account_no;
            bank_name = params.bank_name;
            name_bank_account = params.name_bank_account;
            amount = params.amount_to_pay;
        }
        this.auth.userInfo.pendingPayments != undefined && this.auth.userInfo.pendingPayments.forEach(element => {
            pendingPaymentMessage.push("Pending Payment - " + moment(element.submit_date).format('MM/DD/YYYY hh:mm A') + " for $" + element.payment_amt + "")
        })

        this.state = {
            submited: false,
            countries: [],
            states: [],
            portal: '',
            formType: formType,
            response: {},
            errorMessage: [],
            errorMessageClass: '',
            loader: true,
            full_name: '',
            name_on_card: name_on_card,
            card_no: card_no,
            card_date_object: card_date_object,
            exp_date: exp_date,
            cvv: '',
            country: country,
            address1: address1,
            address2: address2,
            city: city,
            phone: phone,
            zip_code: zip_code,
            state: state,
            account_type: account_type,
            bank_name: bank_name,
            name_bank_account: name_bank_account,
            routing_no: routing_no,
            routing_no_re: routing_no_re,
            account_no: account_no,
            account_no_re: account_no_re,
            redirect: false,
            status_code: '',
            // amount:amount,
            amount: (localStorage.getItem('balance') != null && amount == '' && localStorage.getItem('balance') > 0) ? localStorage.getItem('balance') : amount,
            paymentMethods: [
                { text: 'Credit Card', id: 'creditCard' },
                { text: 'Bank Draft', id: 'bankDraft' },
            ],
            onCustomChange: true,
            pendingPaymentMessage: pendingPaymentMessage
        };
        this.handleChange = this.handleChange.bind(this);
        this.onStateChange = this.onStateChange.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.onCountryChange = this.onCountryChange.bind(this);
        this.cbSelect = this.cbSelect.bind(this);
        this.closePaymentMessageAlert = this.closePaymentMessageAlert.bind(this);


        this.validator = new SimpleReactValidator({
            validators: {
                routing_no: {  // name the rule
                    message: 'Routing number and confirm routing number do not match.',
                    rule: (val) => {
                        if (val.parm1 !== undefined && val.parm2 !== undefined && val.parm1 !== val.parm2) {
                            return false;
                        }
                    },
                },
                account_no: {  // name the rule
                    message: 'Account number and confirm account number do not match.',
                    rule: (val) => {
                        if (val.parm1 !== undefined && val.parm2 !== undefined && val.parm1 !== val.parm2) {
                            return false;
                        }
                    },
                },
                zipRequired: {
                    message: 'The zip code field is required.',
                    rule: (val) => { if (val === undefined || val === '') { return false; } },
                    required: true
                },
                zipNumericValid: {
                    message: 'Please enter numeric value.',
                    rule: (val) => {
                        return /^[0-9]+$/.test(val);
                    },
                    required: true
                },
                zipNumericValidNonUS: {
                    message: 'Please enter only numeric and alphanumeric characters.',
                    rule: (val) => {
                        return /^[a-zA-Z0-9]+$/.test(val);
                    },
                    required: true
                },
                zipLengthValid: {
                    message: 'Please enter zip code between 4 - 8 character.',
                    rule: (val) => {
                        const country = this.state.country;
                        if (val.length < 4 || val.length > 8 && country === 'US') {
                            return false;
                        }
                        else {
                            return true;
                        }
                    },
                    required: true
                },
            }
        });


    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    onStateChange(e) {
        this.setState({ state: e.value });
    }
    handleSelectChange(event) {
        this.validator = new SimpleReactValidator();
        this.handleChange(event);
    }
    cbSelect(event) {
        this.setState({
            submited: false
        });
        this.validator.hideMessages();
    }
    /** 
     * Submit form on press of enter key 
     */
    onEnterPressAction(e) {
        if (e.charCode === 13 || e.keyCode === 13) {
            this.checkLogin();
        }
    }
    checkInteger() {

    }
    handleFormChange(event) {
        if (this.state.onCustomChange == true) {
            this.setState({ formType: event.value });
            this.validator.fields = {};
            this.cbSelect();

            this.setState({
                //formType:params.payment_method,
                routing_no: '',
                routing_no_re: '',
                account_type: '',
                card_no: '',
                exp_date: '',
                name_on_card: '',
                //card_security_code: params.cvv,
                country: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip_code: '',
                phone: '',
                account_no: '',
                account_no_re: '',
                bank_name: '',
                name_bank_account: '',
                card_date_object: ''
                //amount: params.amount_to_pay,
            });
        } else {
            this.setState({ onCustomChange: true });

        }

    }
    onCountryChange(event) {
        var country = event.value;
        if (country !== '') {
            this.getStates(country);
            this.setState({ country: country, state: '' });
        }
    }
    getStates(country) {
        const countriesArray = Object.values(this.countries);

        const foundCountry = countriesArray.find(c => c.code === country);

        if (foundCountry && foundCountry.states) {
            const states = foundCountry.states.map(state => ({
                'text': state.name,
                'id': state.code
            }));

            this.setState({ states });
        } else {
            this.setState({ states: [] });
        }
    }
    /**
     * Prepare countries dropdown data
     */
    setCountries() {
        if (this.countries && Object.keys(this.countries).length > 0) {
            const countries = Object.values(this.countries).map(item => ({
                'text': item.name,
                'id': item.code
            }));

            this.setState({ countries });
        }
    }

    getCountries() {
        if (this.countries.length > 0) {
            this.setCountries();
            return false;
        }
        this.setState({ loader: true });
        axios.post(process.env.REACT_APP_API_URL + 'location/countries').then((response) => {
            this.setState({ loader: false });
            if (response.status === 200) {
                if (typeof (response.data.status) === 'undefined') {
                    alert('Something wrong with API call. Please contact site Admin.');
                } else {
                    if (response.data.status === 'error') {
                        this.setState({
                            errorMessage: [response.data.message],
                            errorMessageClass: 'alert alert-danger'
                        });
                    } else {
                        this.countries = response.data.data;
                        this.setCountries();
                        this.getStates(this.state.country);
                    }
                }
            } else {
                this.setState({ errorMessage: ['Error occcured with status code ' + response.status] });
            }
        }, (error) => {
            this.setState({ loader: false });
            console.log(error);
        });
    }
    handleDateChange = date => {
        if (date === null) {
            this.setState({
                card_date_object: '',
                exp_date: ''
            });
        } else {
            this.setState({
                card_date_object: date,
                exp_date: format(date, "MM/yyyy")
            });
        }
    }
    submitPayment(event) {
        /**
        * Validating form
        */
        let expiration_date = '';
        if (this.state.card_date_object !== '') {
            expiration_date = format(this.state.card_date_object, 'MM-yy');
        }
        var params = {
            // db: localStorage.getItem('customer'),
            amount_to_pay: this.state.amount,
            payment_method: this.state.formType,
            card_number: this.state.card_no,
            expiration_date: expiration_date,
            card_date_object: this.state.card_date_object,
            name_on_card: this.state.name_on_card,
            card_security_code: this.state.cvv,
            country: this.state.country,
            address_line1: this.state.address1,
            address_line2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip_code,
            phone: this.state.phone,
            userInfo: this.auth.userInfo,
            routing_no: this.state.routing_no,
            account_no: this.state.account_no,
            account_type: this.state.account_type,
            bank_name: this.state.bank_name,
            name_bank_account: this.state.name_bank_account,
        };

        this.setState({
            submited: true
        });

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        this.setState({ loader: true });
        axios.post(process.env.REACT_APP_API_URL + 'payment', params, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + localStorage.getItem('paymentToken'),
            }
        }).then((response) => {
            this.setState({ loader: false });
            if (response.status === 200) {
                if (typeof (response.data.status) === 'undefined') {
                    alert('Something wrong with API call. Please contact site Admin.');
                } else {
                    if (response.data.status === 'error') {
                        if (response.data.status_code === '401') {
                            this.setState({
                                status_code: response.data.status_code,
                            });
                        }
                        else {
                            window.scrollTo(0, 0);
                            this.setState({
                                errorMessage: response.data.message,
                                errorMessageClass: 'alert alert-danger'
                            });
                        }
                    } else {
                        params.service_fees = response.data.data.cServiceFee;
                        params.card_type = response.data.data.card_type;
                        localStorage.setItem('params', JSON.stringify(params));
                        this.setState({
                            errorMessage: [response.data.message + " Please wait redirecting to confirmation"],
                            errorMessageClass: 'alert alert-success',
                            redirect: true,
                            submited: false
                        });
                    }
                }
            } else {
                this.setState({ errorMessage: 'Error occcured with status code ' + response.status });
            }
        }, (error) => {
            this.setState({ loader: false });
            console.log(error);
        });
    }
    onlyFloat = e => {
        const re = /^[0-9\b .]+$/;
        if (!re.test(e.key) && e.keyCode !== 8 && e.keyCode !== 9) {
            e.preventDefault();
        }
    };

    redirect() {
        if (this.state.redirect === true) {
            return this.props.navigateTo("/payment-confirm");
        }
    }
    /** For Logout */
    logout = () => {
        var sPropertyTransitId = this.auth.userInfo.sPropertyTransitId;
        localStorage.clear();
        localStorage.setItem('db', sPropertyTransitId);
        localStorage.setItem('customer', sPropertyTransitId);
        window.location.href = '/payment-login/' + sPropertyTransitId;
    }
    createMarkup() {
        return { __html: this.auth.userInfo.portal_service_fee_msg };
    }
    MyComponent() {
        return <div dangerouslySetInnerHTML={this.createMarkup()} />;
    }
    /** End Logout Function */
    /**    
     * Function will identify enter key press.  if enter key is pressed click event for submit button will trigger.
     * @param {*} e for event
     */

    /** Disable Browser back button Function */
    componentDidMount() {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event) {
            window.history.pushState(null, document.title, window.location.href);
        });
        if (this.auth.userInfo.allowedMethod === 'card') {
            this.setState({
                paymentMethods: [
                    { text: 'Credit Card', id: 'creditCard' }
                ], formType: 'creditCard', onCustomChange: false
            });
        } else if (this.auth.userInfo.allowedMethod === 'bank') {
            this.setState({
                paymentMethods: [
                    { text: 'Bank Draft', id: 'bankDraft' },
                ], formType: 'bankDraft', onCustomChange: false
            });
        }
        this.getCountries();
        window.scrollTo(0, 0);
    }

    /** End Disable Browser back button Function */
    closePaymentMessageAlert() {
        this.setState({ pendingmessage: [] })
    }
    pendingmessage() {
        return (this.state.pendingPaymentMessage).map((keyName, i) => (
            <>{keyName}{this.state.pendingPaymentMessage.length == i + 1 ? '' : <br />}</>
        ))
    }
    render() {
        this.validator.purgeFields();
        const { navigate } = this.state;
        if (this.auth.userInfo.length <= 0 || this.auth.db === null) {
            return this.props.navigateTo("/payment-login/" + this.auth.db);
        }
        if (this.state.status_code) {
            return this.props.navigateTo("/payment-login/" + this.auth.db);
        }
        if (navigate) {
            return this.props.navigateTo("/");
        }
        /**
         * field class validation
         */
        this.validator.message('amount', this.state.amount, 'required|currency|min:0,num')
        return (
            <div className="payment-portal-wrapper">
                <Authenticate token='' url='payment-login/' />
                {this.redirect()}
                <div className="payment-portal-header">
                    <div className="payment-portal-container-main">
                        <div className="payment-portal-logo"><img src={logo} alt="Logo" /></div>
                        <div className="header-account-links">
                            <ul>
                                <li><span className="logout-text">{this.auth.userInfo.customer_name}, </span><Link to="#" onClick={this.logout}><span className="logout-text">Logout</span> <i className="fa fa-power-off fa-lg" title="Log Out"></i></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* {this.auth.userInfo.customer_name}, {this.auth.userInfo.sCompanyName} has requested an amount to be paid. */}
                {this.auth.userInfo.use_propertywebpay_flag == false && <div><br /><span className="bold-red">Payments are currently disabled.</span></div>}
                {this.MyComponent()}

                <div className="payment-portal-container">
                    <div className={this.state.errorMessageClass}>
                        {Object.keys(this.state.errorMessage).map((keyName, i) => (
                            <span key={i}>{this.state.errorMessage[keyName]}<br /></span>
                        ))}
                        {this.state.pendingPaymentMessage.length > 0 && this.state.errorMessage.length == 0 && <Alert color="success" dismiss onClose={this.closePaymentMessageAlert} >{this.pendingmessage()}</Alert>}
                    </div>
                    <h2>Payment Type</h2>
                    <label>Payment</label>
                    <Select type="workOrderForm" data-minimum-results-for-search="Infinity" name="paymentType" className="select2-drop" value={this.state.formType} data={this.state.paymentMethods} onSelect={this.cbSelect} options={{ placeholder: 'Credit Card or Bank Draft' }} onChange={this.handleFormChange} />
                    <p>Note: All payments made with Credit Cards are subject to a service fee.</p>
                    <div className="">
                        {(this.state.formType === 'creditCard') ?
                            (<CreditCardForm payment={this} onChange={this.handleChange} countryChange={this.onCountryChange} onStateChange={this.onStateChange} />)
                            :
                            (<BankDraftForm payment={this} onChange={this.handleChange} countryChange={this.onCountryChange} onStateChange={this.onStateChange} />)}
                    </div>
                    <h2>Payment Amount</h2>
                    <div className="checkbox-container">
                        <label className="required">Amount</label>
                        <div className={this.validator.fieldValid('amount') === false && this.state.submited === true ? 'select-error' : ''}>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                </div>
                                <input type="text" autoComplete="off" className="form-control log-in-input" name="amount" placeholder="Amount" onChange={this.handleChange} onKeyDown={this.onlyFloat} value={this.state.amount} />
                            </div>
                            {this.validator.message('amount', this.state.amount, 'required|currency|min:0,num')}
                        </div>
                    </div>
                    {this.auth.userInfo.use_propertywebpay_flag == true && <button className="button-secondary" type="button" onClick={() => this.submitPayment()}>Continue Payment</button>}
                    {this.auth.userInfo.use_propertywebpay_flag == false && <div><br /><br /><span className="bold-red">Payments are currently disabled.</span></div>}
                    <div className="payment-portal-disclaimer">
                        <p>This information was obtained from sources believed to be reliable, but no representations or warranties, expressed or implied, as to the accuracy of the information are made.  You are responsible for verifying the information and bear  all risk for inaccuracies.  Pricing and other details are subject to change.  Contact the leasing office for the latest information.</p>
                        <Link to="/terms-and-conditions" target='_blank' className="simple-link">View Terms &amp; Conditions</Link>
                        <img src={logo} alt="Logo" />
                    </div>
                </div>
                <Loader show={this.state.loader} />
            </div>
        );
    }
}

class CreditCardForm extends Component {
    constructor(props) {
        super(props);
        this.props.payment.setCountries();
        //this.props.payment.state.card_date_object = '';
    }
    handleKeyDown = e => {
        const re = /^[0-9\b]+$/;
        if (!re.test(e.key) && e.keyCode !== 8 && e.keyCode !== 9) {
            e.preventDefault();
        }
    };
    monthYear = e => {
        const re = /^[0-9\b /]+$/;
        if (!re.test(e.key) && e.keyCode !== 8 && e.keyCode !== 9) {
            e.preventDefault();
        }
    };
    render() {
        /**
         * field validation class
         */
        const { country } = this.props.payment.state;
        const zipValidationRule = country === 'US' ? 'zipNumericValid' : 'zipNumericValidNonUS';
        this.props.payment.validator.message('name_on_card', this.props.payment.state.name_on_card, 'required')
        this.props.payment.validator.message('card_no', this.props.payment.state.card_no, 'required|card_num')
        this.props.payment.validator.message('exp_date', this.props.payment.state.exp_date, 'required|card_exp')
        this.props.payment.validator.message('cvv', this.props.payment.state.cvv, 'required|integer')
        this.props.payment.validator.message('country', this.props.payment.state.country, 'required')
        this.props.payment.validator.message('address1', this.props.payment.state.address1, 'required')
        this.props.payment.validator.message('city', this.props.payment.state.city, 'required|alpha_num_dash_space')
        this.props.payment.validator.message('phone', this.props.payment.state.phone, 'required|phone')
        this.props.payment.validator.message('zip_code', this.props.payment.state.zip_code, `zipRequired|${zipValidationRule}|zipLengthValid`)
        this.props.payment.validator.message('state', this.props.payment.state.state, 'required')

        let valName = this.props.payment.validator.fieldValid('name_on_card') === false && this.props.payment.state.submited === true ? 'log-in-input inValid' : 'log-in-input';
        let valErrorCard = this.props.payment.validator.fieldValid('card_no') === false && this.props.payment.state.submited === true ? 'log-in-input inValid' : 'log-in-input';
        let valDatePicker = this.props.payment.validator.fieldValid('exp_date') === false && this.props.payment.state.submited === true ? 'log-in-input inValid' : 'log-in-input';
        let valCvv = this.props.payment.validator.fieldValid('cvv') === false && this.props.payment.state.submited === true ? 'log-in-input inValid' : 'log-in-input';
        let valCountry = this.props.payment.validator.fieldValid('country') === false && this.props.payment.state.submited === true ? 'select-error' : '';
        let valAddress = this.props.payment.validator.fieldValid('address1') === false && this.props.payment.state.submited === true ? 'log-in-input inValid' : 'log-in-input';
        let valCity = this.props.payment.validator.fieldValid('city') === false && this.props.payment.state.submited === true ? 'log-in-input inValid' : 'log-in-input';
        let valPhone = this.props.payment.validator.fieldValid('phone') === false && this.props.payment.state.submited === true ? 'log-in-input inValid' : 'log-in-input';
        let valZip = this.props.payment.validator.fieldValid('zip_code') === false && this.props.payment.state.submited === true ? 'log-in-input inValid' : 'log-in-input';
        let valState = this.props.payment.validator.fieldValid('state') === false && this.props.payment.state.submited === true ? 'select-error' : '';
        const defaultMinDate = new Date();
        defaultMinDate.setHours(0, 0, 0, 0);
        defaultMinDate.setDate(1);
        // const CustomInput = ({ value, onClick }) => (
        //     <input type="text" className={valDatePicker} onClick={onClick} value={value} placeholder="Expiry Date"/>
        // );
        return (
            <div>
                <h2>Payment Details</h2>
                <label className="required">Name on Card</label>
                <input type="text" autoComplete="off" name="name_on_card" className={valName} placeholder="Name on Card" onChange={this.props.onChange} value={this.props.payment.state.name_on_card} />
                {this.props.payment.validator.message('name_on_card', this.props.payment.state.name_on_card, 'required')}
                <label className="required">Card Number</label>
                <input type="text" autoComplete="off" name="card_no" className={valErrorCard} placeholder="Card Number" onChange={this.props.onChange} onKeyDown={this.handleKeyDown} value={this.props.payment.state.card_no} />
                {this.props.payment.validator.message('card_no', this.props.payment.state.card_no, 'required|card_num')}
                <div className="half-input">
                    <label className="required">Expiry Date</label>
                    <DatePicker
                        selected={this.props.payment.state.card_date_object}
                        onChange={this.props.payment.handleDateChange}
                        dateFormat="MM/yyyy"
                        minDate={defaultMinDate}
                        showMonthYearPicker
                        name="exp_date"
                        className={valDatePicker}
                        placeholderText="Expiry Date (Format: mm/yyyy)"
                        onKeyDown={this.monthYear}
                        autoComplete="off"
                    />
                    {this.props.payment.validator.message('exp_date', this.props.payment.state.exp_date, 'required|card_exp')}
                </div>
                <div className="half-input right">
                    <label className="required">CVV</label>
                    <input type="password" name="cvv" className={valCvv} autoComplete="off" placeholder="CVV" onChange={this.props.onChange} onKeyDown={this.handleKeyDown} maxLength="4" />
                    {this.props.payment.validator.message('cvv', this.props.payment.state.cvv, 'required|integer')}
                </div>
                <label className="required">Country</label>
                <div className={valCountry}>
                    <Select type="workOrderForm" autoComplete="off" onOpen={(e) => (document.querySelector('.select2-search__field').focus())} name="country" defaultValue={this.props.payment.state.country} value={this.props.payment.state.country} data={this.props.payment.state.countries} className="select2-drop" options={{ placeholder: 'Country' }} onChange={this.props.countryChange} searchType={true} />
                </div>
                {this.props.payment.validator.message('country', this.props.payment.state.country, 'required')}
                <label className="required">Address 1</label>
                <input type="text" autoComplete="off" name="address1" className={valAddress} placeholder="Address 1" onChange={this.props.onChange} value={this.props.payment.state.address1} />
                {this.props.payment.validator.message('address1', this.props.payment.state.address1, 'required')}
                <label>Address 2</label>
                <input type="text" autoComplete="off" name="address2" className="log-in-input" placeholder="Address 2" onChange={this.props.onChange} value={this.props.payment.state.address2} />
                <div className="half-input">
                    <label className="required">City</label>
                    <input type="text" autoComplete="off" name="city" className={valCity} placeholder="City" onChange={this.props.onChange} value={this.props.payment.state.city} />
                    {this.props.payment.validator.message('city', this.props.payment.state.city, 'required|alpha_num_dash_space')}
                </div>
                <div className="half-input right">
                    <label className="required">Phone</label>
                    {/* <input type="text" name="phone" className={valPhone} placeholder="Phone (123-456-7890)" onChange={this.props.onChange} /> */}
                    <PhoneInput
                        country={'us'}
                        onlyCountries={['us']}
                        value={this.props.payment.state.phone}
                        disableDropdown={true}
                        disableCountryCode={true}
                        onChange={(value, country, e, formattedValue) => { this.props.onChange(e) }}
                        inputProps={{
                            name: 'phone',
                            placeholder: "Phone (123-456-7890)",
                            className: valPhone
                        }}
                        autoComplete="off"
                    />
                    {this.props.payment.validator.message('phone', this.props.payment.state.phone, 'required|phone')}
                </div>
                <div className="half-input">
                    <label className="required">Zip Code</label>
                    <input type="text" autoComplete="off" name="zip_code" className={valZip} placeholder="Zip Code" onChange={this.props.onChange} value={this.props.payment.state.zip_code} />
                    {this.props.payment.validator.message('zip_code', this.props.payment.state.zip_code, `zipRequired|${zipValidationRule}|zipLengthValid`)}
                </div>
                <div className="half-input right">
                    <label className="required">State</label>
                    <div className={valState}>
                        {this.props.payment.state.states.length > 0 ? <Select type="paymentLoginForm" autoComplete="off" onOpen={(e) => (document.querySelector('.select2-search__field').focus())} name="state" data={this.props.payment.state.states} value={this.props.payment.state.state} defaultValue={this.props.payment.state.state} className="select2-drop" options={{ placeholder: 'State' }} onChange={this.props.onStateChange} searchType={true} /> : <input type="text" autoComplete="off" name="state" className={this.props.payment.validator.fieldValid('state') === false && this.props.payment.state.submited === true ? 'log-in-input inValid' : 'log-in-input'} placeholder="State Code" onChange={this.props.onChange} value={this.props.payment.state.state} />}
                        {this.props.payment.validator.message('state', this.props.payment.state.state, 'required')}
                    </div>
                </div>
            </div>
        );
    }
}
class BankDraftForm extends Component {
    constructor(props) {
        super(props);
        this.props.payment.setCountries();
        // this.props.payment.state.country = 'US';
        // this.props.payment.state.state = '';
    }
    render() {

        /**
         * field class validation
         */
        const { country } = this.props.payment.state
        const zipValidationRule = country === 'US' ? 'zipNumericValid' : 'zipNumericValidNonUS';
        this.props.payment.validator.message('account_type', this.props.payment.state.account_type, 'required')
        this.props.payment.validator.message('routing_no', this.props.payment.state.routing_no, 'required|min:9|max:9')
        this.props.payment.validator.message('routing_no_', { "parm1": this.props.payment.state.routing_no, "parm2": this.props.payment.state.routing_no_re }, 'routing_no')
        this.props.payment.validator.message('routing_no_re', this.props.payment.state.routing_no_re, 'required|min:9|max:9')
        this.props.payment.validator.message('account_no', this.props.payment.state.account_no, 'required')
        this.props.payment.validator.message('account_no_re', this.props.payment.state.account_no_re, 'required')
        this.props.payment.validator.message('account_no_', { "parm1": this.props.payment.state.account_no, "parm2": this.props.payment.state.account_no_re }, 'account_no')
        this.props.payment.validator.message('bank_name', this.props.payment.state.bank_name, 'required')
        this.props.payment.validator.message('name_bank_account', this.props.payment.state.name_bank_account, 'required')
        this.props.payment.validator.message('country', this.props.payment.state.country, 'required')
        this.props.payment.validator.message('address1', this.props.payment.state.address1, 'required')
        this.props.payment.validator.message('city', this.props.payment.state.city, 'required')
        this.props.payment.validator.message('zip_code', this.props.payment.state.zip_code, `zipRequired|${zipValidationRule}|zipLengthValid`)
        this.props.payment.validator.message('state', this.props.payment.state.state, 'required')
        this.props.payment.validator.message('phone', this.props.payment.state.phone, 'required')

        let valRoutingNumber = (this.props.payment.validator.fieldValid('routing_no') === false || this.props.payment.validator.fieldValid('routing_no_') === false) && this.props.payment.state.submited === true ? 'log-in-input inValid' : 'log-in-input';
        let valRoutingNumberReEnter = (this.props.payment.validator.fieldValid('routing_no_re') === false || this.props.payment.validator.fieldValid('routing_no_') === false) && this.props.payment.state.submited === true ? 'log-in-input inValid' : 'log-in-input';
        let valAccountNumber = (this.props.payment.validator.fieldValid('account_no') === false || this.props.payment.validator.fieldValid('account_no_') === false) && this.props.payment.state.submited === true ? 'log-in-input inValid' : 'log-in-input';

        let valAccountNumberReEnter = (this.props.payment.validator.fieldValid('account_no_re') === false || this.props.payment.validator.fieldValid('account_no_') === false) && this.props.payment.state.submited === true ? 'log-in-input inValid' : 'log-in-input';
        let valBankName = this.props.payment.validator.fieldValid('bank_name') === false && this.props.payment.state.submited === true ? 'log-in-input inValid' : 'log-in-input';
        let valBankAccountName = this.props.payment.validator.fieldValid('name_bank_account') === false && this.props.payment.state.submited === true ? 'log-in-input inValid' : 'log-in-input';
        let valCountry = this.props.payment.validator.fieldValid('country') === false && this.props.payment.state.submited === true ? 'select-error' : '';
        let valAddress = this.props.payment.validator.fieldValid('address1') === false && this.props.payment.state.submited === true ? 'log-in-input inValid' : 'log-in-input';
        let valCity = this.props.payment.validator.fieldValid('city') === false && this.props.payment.state.submited === true ? 'log-in-input inValid' : 'log-in-input';
        let valZip = this.props.payment.validator.fieldValid('zip_code') === false && this.props.payment.state.submited === true ? 'log-in-input inValid' : 'log-in-input';
        let valState = this.props.payment.validator.fieldValid('state') === false && this.props.payment.state.submited === true ? 'select-error' : '';
        let valPhone = this.props.payment.validator.fieldValid('phone') === false && this.props.payment.state.submited === true ? 'log-in-input inValid' : 'log-in-input';

        return (
            <div>
                <h2>Bank Draft Info</h2>
                <h4 className="acname required">Account Type</h4>
                <div>
                    <div className="half-input radio">
                        <div className="checking-radio">
                            <input type="radio" value="checking" checked={this.props.payment.state.account_type === "checking"} id="checking" name="account_type" placeholder="" onChange={this.props.onChange} />
                            <label htmlFor="checking">Checking</label>
                        </div>
                    </div>
                    <div className="half-input radio right">
                        <div className="checking-radio">
                            <input type="radio" value="savings" checked={this.props.payment.state.account_type === "savings"} id="account_type" name="account_type" placeholder="" onChange={this.props.onChange} />
                            <label htmlFor="account_type">Savings</label>
                        </div>
                    </div>
                    {this.props.payment.validator.message('account_type', this.props.payment.state.account_type, 'required')}
                </div>
                <div>
                    <div className="half-input">
                        <label className="required">Routing Number</label>
                        <input type="text" onCopy={(e) => e.preventDefault()} onPaste={(e) => e.preventDefault()} onCut={(e) => { e.preventDefault() }} autoComplete="off" name="routing_no" className={valRoutingNumber} placeholder="Routing No" onChange={this.props.onChange} value={this.props.payment.state.routing_no} />
                        {this.props.payment.validator.message('routing_no', this.props.payment.state.routing_no, 'required|min:9|max:9')}
                        {this.props.payment.validator.message('routing_no_', { "parm1": this.props.payment.state.routing_no, "parm2": this.props.payment.state.routing_no_re }, 'routing_no')}
                    </div>
                    <div className="half-input right">
                        <label className="required">Re-enter Routing Number</label>
                        <input type="text" onCopy={(e) => e.preventDefault()} onPaste={(e) => e.preventDefault()} onCut={(e) => { e.preventDefault() }} autoComplete="off" name="routing_no_re" required className={valRoutingNumberReEnter} placeholder="Re-enter Routing Number" onChange={this.props.onChange} value={this.props.payment.state.routing_no_re} />
                        {this.props.payment.validator.message('routing_no_re', this.props.payment.state.routing_no_re, 'required|min:9|max:9')}
                        {this.props.payment.validator.message('routing_no_', { "parm1": this.props.payment.state.routing_no, "parm2": this.props.payment.state.routing_no_re }, 'routing_no')}
                    </div>
                    <div className="half-input">
                        <label className="required">Account Number</label>
                        <input type="text" onCopy={(e) => e.preventDefault()} onPaste={(e) => e.preventDefault()} onCut={(e) => { e.preventDefault() }} autoComplete="off" name="account_no" className={valAccountNumber} placeholder="Account No" onChange={this.props.onChange} value={this.props.payment.state.account_no} />
                        {this.props.payment.validator.message('account_no', this.props.payment.state.account_no, 'required')}
                        {this.props.payment.validator.message('account_no_', { "parm1": this.props.payment.state.account_no, "parm2": this.props.payment.state.account_no_re }, 'account_no')}

                    </div>
                    <div className="half-input right">
                        <label className="required">Re-enter Account Number</label>
                        <input type="text" onCopy={(e) => e.preventDefault()} onPaste={(e) => e.preventDefault()} onCut={(e) => { e.preventDefault() }} autoComplete="off" name="account_no_re" className={valAccountNumberReEnter} placeholder="Re-enter Account Number" onChange={this.props.onChange} value={this.props.payment.state.account_no_re} />
                        {this.props.payment.validator.message('account_no_re', this.props.payment.state.account_no_re, 'required')}
                        {this.props.payment.validator.message('account_no_', { "parm1": this.props.payment.state.account_no, "parm2": this.props.payment.state.account_no_re }, 'account_no')}
                    </div>
                </div>
                <div>
                    <label className="required">Name of Bank</label>
                    <input type="text" autoComplete="off" name="bank_name" className={valBankName} placeholder="Name of Bank" onChange={this.props.onChange} value={this.props.payment.state.bank_name} />
                    {this.props.payment.validator.message('bank_name', this.props.payment.state.bank_name, 'required')}
                </div>
                <div>
                    <label className="required">Account holder's Name</label>
                    <input type="text" autoComplete="off" name="name_bank_account" className={valBankAccountName} placeholder="Account holder's Name" onChange={this.props.onChange} value={this.props.payment.state.name_bank_account} />
                    {this.props.payment.validator.message('name_bank_account', this.props.payment.state.name_bank_account, 'required')}
                </div>
                <h2>Billing Info</h2>
                <label className="required">Country</label>
                <div className={valCountry}>
                    <Select type="workOrderForm" autoComplete="off" onOpen={(e) => (document.querySelector('.select2-search__field').focus())} name="country" defaultValue={this.props.payment.state.country} data={this.props.payment.state.countries} className="select2-drop" options={{ placeholder: 'Country' }} onChange={this.props.countryChange} value={this.props.payment.state.country} searchType={true} />

                    {this.props.payment.validator.message('country', this.props.payment.state.country, 'required')}
                </div>
                <label className="required">Address 1</label>
                <input type="text" autoComplete="off" name="address1" className={valAddress} placeholder="Address 1" onChange={this.props.onChange} value={this.props.payment.state.address1} />
                {this.props.payment.validator.message('address1', this.props.payment.state.address1, 'required')}
                <label>Address 2</label>
                <input type="text" autoComplete="off" name="address2" className="log-in-input" placeholder="Address 2" onChange={this.props.onChange} value={this.props.payment.state.address2} />
                <div className="">
                    <label className="required">City</label>
                    <input type="text" autoComplete="off" name="city" className={valCity} placeholder="City" onChange={this.props.onChange} value={this.props.payment.state.city} />
                    {this.props.payment.validator.message('city', this.props.payment.state.city, 'required')}
                </div>
                <div className="half-input">
                    <label className="required">Zip Code</label>
                    <input type="text" autoComplete="off" name="zip_code" className={valZip} placeholder="Zip Code" onChange={this.props.onChange} value={this.props.payment.state.zip_code} />
                    {this.props.payment.validator.message('zip_code', this.props.payment.state.zip_code, `zipRequired|${zipValidationRule}|zipLengthValid`)}
                </div>
                <div className="half-input right">
                    <label className="required">State</label>
                    <div className={valState}>
                        {this.props.payment.state.states.length > 0 ? <Select type="paymentLoginForm" autoComplete="off" onOpen={(e) => (document.querySelector('.select2-search__field').focus())} name="state" data={this.props.payment.state.states} value={this.props.payment.state.state} className="select2-drop" options={{ placeholder: 'State' }} onChange={this.props.onStateChange} searchType={true} /> : <input type="text" autoComplete="off" name="state" className={this.props.payment.validator.fieldValid('state') === false && this.props.payment.state.submited === true ? 'log-in-input inValid' : 'log-in-input'} placeholder="State Code" onChange={this.props.onChange} value={this.props.payment.state.state} />}
                        {this.props.payment.validator.message('state', this.props.payment.state.state, 'required')}
                    </div>
                </div>
                <label className="required">Phone</label>
                {/* <input type="text" name="phone" className={valPhone} placeholder="Phone (123-456-7890)" onChange={this.props.onChange} /> */}
                <PhoneInput
                    country={'us'}
                    onlyCountries={['us']}
                    value={this.props.payment.state.phone}
                    disableDropdown={true}
                    disableCountryCode={true}
                    onChange={(value, country, e, formattedValue) => { this.props.onChange(e) }}
                    inputProps={{
                        name: 'phone',
                        placeholder: "Phone (123-456-7890)",
                        className: valPhone
                    }}
                    autoComplete="off"
                />
                {this.props.payment.validator.message('phone', this.props.payment.state.phone, 'required')}
            </div>
        );
    }
}
export default withNavigation(Payment);