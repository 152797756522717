import React, { useEffect, useState } from 'react';
import Select, {createFilter} from 'react-select';

const Select2 = ({ name, value, onChange, data, options, className, styles, type, disabled, searchType }) => {

    const [selectedVal, setSelectedVal] = useState(value);

    useEffect(() => {
        setSelectedVal(value);
    }, [value]);

    const optionData = data != '' && data != undefined && data.map((item) => ({ label: item.text, value: item.id }));

    const customStyles = {
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            ...styles,
            cursor: "pointer",
            fontSize: '16px', // Set the font size here
            fontFamily: 'Roboto, sans-serif', // Set the font family here
            fontWeight: 'normal', // Set the font weight for the options
            borderWidth: (state.selectProps.className.includes('invalid') || state.selectProps.className.includes('select-error')) ? '0' : defaultStyles.borderWidth,
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: "unset",
            borderColor: "#888 transparent transparent transparent",
            borderStyle: "solid",
            borderWidth: "5px 4px 0 4px",
            height: " 0",
            right: "6%",
            marginTop: "-2px",
            position: "absolute",
            top: "50%",
            width: "0",
            boxSizing: "border-box",
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: 'none',
        }),
        input: (provided, state) => ({
            ...provided,
            margin: '-2px',
            marginBottom: '0px',
            paddingBottom: '0px',
            paddingTop: '0px',
            border: 'none',

        }),
        options: (provided, state) => ({
            ...provided,
            width: '92%',
            fontWeight: 'normal', // Set the font weight for the options
            fontSize: '16px', // Set the font size for the options
            fontFamily: 'lato, sans-serif', // Set the font family for the options
            // Add other styles you want to customize the options
        }),
        ...((type !== undefined && type === 'workOrderFilter') && {
            menu: (provided, state) => ({
                ...provided,
                width: '185px',
                fontSize: '16px',
                fontWeight: 400,
                overflow: 'hidden'
                // Add other styles you want to customize the menu
            }),
            // Style for the single-value (selected) option
            singleValue: (provided, state) => ({
                ...provided,
                color: '#777',
                fontWeight: 700,
                fontFamily: 'lato, sans-serif',
                marginTop: '-2px',
                fontSize: '14px'
                // Add other styles you want to customize the selected option
            }),
        }),
        ...((type !== undefined && type === 'workOrderForm') && {
            placeholder: (provided, state) => ({
                ...provided,
                color: '#777',
                lineHeight: '32px',
                fontSize: '13px',
                left: "93%",
                fontFamily: 'lato, sans-serif',
                marginLeft: '5px'
            }),
            singleValue: (provided, state) => ({
                ...provided,
                color: '#777',
                lineHeight: '32px',
                fontSize: '14px',
                fontFamily: 'lato, sans-serif',
                marginLeft: '5px'
            }),
            indicatorContainer: (provided, state) => ({
                ...provided,
                marginLeft: '15px',
                width: 'auto'
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
                padding: "unset",
                borderColor: "#888 transparent transparent transparent",
                borderStyle: "solid",
                borderWidth: "5px 4px 0 4px",
                height: " 0",
                left: "96%",
                // marginLeft: "-4px",
                marginTop: "-2px",
                position: "absolute",
                top: "50%",
                width: "0",
                boxSizing: "border-box",
            }),

        }),
        ...((type !== undefined && type === 'contactUpdateForm') && {
            control: (defaultStyles) => ({
                ...defaultStyles,
                minHeight: '50px', // Set the desired height here
                height: '50px', // Set the desired height here
                padding: "0px !important",

            }),
        }),
        ...((type !== undefined && type === 'paymentLoginForm') && {
            placeholder: (provided, state) => ({
                ...provided,
                color: '#777',
                lineHeight: '32px',
                fontSize: '13px',
                left: "93%",
                fontFamily: 'lato, sans-serif',
                marginLeft: '5px'
            }),
            singleValue: (provided, state) => ({
                ...provided,
                color: '#777',
                lineHeight: '32px',
                fontSize: '14px',
                fontFamily: 'lato, sans-serif',
                marginLeft: '5px'
            })
        }),
    };
    const filterConfig = {
        ignoreCase: true,
        ignoreAccents: true,
        trim: true,
        matchFrom: searchType == true ? 'start' : 'any' ,
    };

    const defaultOption = optionData != '' && optionData.find((item) => item.value === selectedVal);

    return (
        <Select
            name={name}
            defaultValue={
                selectedVal !== '' && defaultOption ? { label: defaultOption.label, value: selectedVal } : null
            }
            value={selectedVal !== '' && defaultOption ? defaultOption : null}
            onChange={onChange}
            closeMenuOnSelect
            placeholder={(selectedVal == '' || defaultOption == '') ? options.placeholder : ''}
            className={`${className} select-drop`}
            options={optionData.length > 0 ? optionData : [{ label: 'No data exist', value: '' }]}
            styles={customStyles}
            isDisabled={disabled}
            filterOption={createFilter(filterConfig)}
        />
    );
};


export default Select2;